import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Kana`}</strong>{` (كان) is a frequently used verb in Arabic Grammar. It has been widely used multiple places in Quran as well, for example`}</p>
    <ul>
      <li parentName="ul">{`وَمَا `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`كَانَ`}</strong>{` اللَّـهُ لِيُعَذِّبَهُمْ وَأَنتَ فِيهِمْ ۚ وَمَا كَانَ اللَّـهُ مُعَذِّبَهُمْ وَهُمْ يَسْتَغْفِرُونَ in (سورة الأنفال: 33)`}</li>
      <li parentName="ul">{`وَمَا `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`كَانَ`}</strong>{` اللَّـهُ لِيُضِلَّ قَوْمًا بَعْدَ إِذْ هَدَاهُمْ حَتَّىٰ يُبَيِّنَ لَهُم مَّا يَتَّقُونَ ۚ إِنَّ اللَّـهَ بِكُلِّ شَيْءٍ عَلِيمٌ in (سورة التوبة: 115) `}</li>
    </ul>
    <br />
    <p>{`Sentence construction of kana and Sisters is simple but may get tricky when making sentence more eloquent. We will begin the discussion with a simple definition of kana and then we will try to drill down into few rules of kana and sisters.`}</p>
    <p>{`So without further ado, lets make the concept of kana and its sisters Super Easy :) (إن شاء الله) `}</p>
    <h2>{`What is Kana and its sisters?`}</h2>
    <p>{`In Arabic Grammar, كان is an incomplete verb having two parts`}</p>
    <ul>
      <li parentName="ul">{`اسم كان (Ism Kana)`}</li>
      <li parentName="ul">{`خبر كان (Khabar Kana)`}</li>
    </ul>
    <br />
    <p>{`By incomplete we mean that كان has no doer but mention of time in the sentence. e.g. كان `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`الطالبُ`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`مجتهداً`}</strong>{` (The student was hardworking)  `}</p>
    <br />
    <p>{`Irab of  كان الطالبُ مجتهداً will be,`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`كان`}</strong>{`
فعل ماض ناقص ناسخ`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الطالب`}</strong>{`
اسم كان`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`مجتهداً`}</strong>{`
خبر كان`}</p>
    </blockquote>
    <h2>{`Kana and Sisters Arabic Definition`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`كان وأخواتها أفعال ناسخة ترفع المبتدأ ويسمى اسم كان وتنصب الخبر ويسمى خبر كان`}</strong></p>
    </blockquote>
    <br />
    <p>{`There are 12 sisters of كان which are as follows:`}</p>
    <ul>
      <li parentName="ul">{`كان (to become)`}</li>
      <li parentName="ul">{`أمسى (to become)`}</li>
      <li parentName="ul">{`أصبح (to become)`}</li>
      <li parentName="ul">{`أضحى (to become)`}</li>
      <li parentName="ul">{`بات (to become)`}</li>
      <li parentName="ul">{`صار (to become)`}</li>
      <li parentName="ul">{`ليس (negation)`}</li>
      <li parentName="ul">{`ظل (to continue)`}</li>
      <li parentName="ul">{`ما انفك (to continue) `}</li>
      <li parentName="ul">{`ما فتيء (to continue)`}</li>
      <li parentName="ul">{`ما برح (to continue)`}</li>
      <li parentName="ul">{`ما زال (to continue) `}</li>
      <li parentName="ul">{`ما دام (as long as)`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.877300613496935%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABSklEQVQoz21SiU7CQBDt/38RGgIajhCDQDkLqIFYK73v7nbb7XMgqOWYZDLHezP7drMK7lhVVbVYgQmJlMtTfondmnJvSd2PtrcyTN7cM44bvL5cuV5yrTTPORbaB4bqGlmWQkp5w6nPKr/XqhOEEIjjGI5jwzAMHL4N+J4H0zRh2zaiMKSD8svlZ+UnhZKKopTIWI4oTuAHIYIwQkg5o17GODgdkhcFGCdOkpzwmGLGOc2Wf6IUiASpPkb0OUa4f0X6pSLWVST66JTzYIPYWMKfzuBOVUTaCu5kDH85h7eYwxkNYa82eF/qYDGHUqQOrFkD3roNa/4Aa9GEq7URblvUe4KvD2BpHZidDnatJqx+D7vHBg79Lg69LsznFvSXEdTBFoEdksKK3qHkdO/80o+9kmIlqBYU/r1gjKCcKMShWooCJX2tit7uBzCDZo5ykZWhAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Kana And Its Sisters",
          "title": "Kana And Its Sisters",
          "src": "/static/8d767450ce31615983f6376bda43f9bb/a6d36/kana-and-sisters.png",
          "srcSet": ["/static/8d767450ce31615983f6376bda43f9bb/222b7/kana-and-sisters.png 163w", "/static/8d767450ce31615983f6376bda43f9bb/ff46a/kana-and-sisters.png 325w", "/static/8d767450ce31615983f6376bda43f9bb/a6d36/kana-and-sisters.png 650w", "/static/8d767450ce31615983f6376bda43f9bb/49217/kana-and-sisters.png 701w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Type of ما used in sisters of Kana`}</h3>
    <p>{`We have seen above in 5 sisters of Kana we use ما, its characteristics are as follows,`}</p>
    <ul>
      <li parentName="ul">{`ما in all the sister of كان is ما نافية, except in case of دام`}</li>
      <li parentName="ul">{`In case of دام, the ما is ما مصدرية ظرفية i.e. we can replace ما دام with its مصدر`}
        <ul parentName="li">
          <li parentName="ul">{`e.g.سأتعلم `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`ما دمت`}</strong>{` حياً Or we can say سأتعلم `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مدة دوامي`}</strong>{` حياً`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Rules of preceding Khabar Kana w.r.t Ism Kana (ما حكم تقديم خبر كان على اسمها)`}</h2>
    <p>{`It is permissible to bring khabar of Kana before Ism of Kana. i.e.`}</p>
    <blockquote>
      <p parentName="blockquote">{`يجوز تقديم خبر كان على اسمها`}</p>
    </blockquote>
    <br />
    <p>{`Example: كان الطالبُ `}<strong parentName="p">{`مجتهداً`}</strong>{` and كان `}<strong parentName="p">{`مجتهداً`}</strong>{` الطالبُ both are correct.`}<br parentName="p"></br>{`
`}{`Examples from Quran:  وكان حقاً علينا نصرُ المؤمنين`}</p>
    <br />
    <p>{` I'rab of `}<strong parentName="p">{`كان مجتهداً الطالبُ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`مجتهداً`}</strong>{`
خبر كان مقدم`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الطالب`}</strong>{`
اسم كان مؤخر`}</p>
    </blockquote>
    <br />
    <p>{`I'rab of `}<strong parentName="p">{`وكان حقاً علينا نصرُ المؤمنين`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`حقاً`}</strong>{`
خبر كان مقدم`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`نصر`}</strong>{`
اسم كان مؤخر`}</p>
    </blockquote>
    <h2>{`Rules of preceding Khabar Kana w.r.t Kana and Ism Kana (ما حكم تقديم خبر كان على كان واسمها)`}</h2>
    <p>{`It is permissible to bring khabar of Kana even before Kana and its Ism. i.e.`}</p>
    <blockquote>
      <p parentName="blockquote">{`يجوز تقديم خبر كان على كان و اسمها`}</p>
    </blockquote>
    <br />
    <p>{`Example: كان الطالبُ `}<strong parentName="p">{`مجتهداً`}</strong>{` and `}<strong parentName="p">{`مجتهداً`}</strong>{` كان الطالبُ both are correct.`}</p>
    <p>{`Exception:
{: .exception}`}</p>
    <ul>
      <li parentName="ul">{`لا يجوز تقديم خبر ليس على ليس i.e. `}<s>{` البرَّ ليس أن تأتوا `}</s></li>
      <li parentName="ul">{`لا يجوز تقديم خبر على هذه الأفعال`}
        <ul parentName="li">
          <li parentName="ul">{`ما انفك  `}</li>
          <li parentName="ul">{`ما فتيء `}</li>
          <li parentName="ul">{`ما برح`}</li>
          <li parentName="ul">{`ما زال`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Types of Kana (كان)`}</h2>
    <p>{`Lets discuss what is فعل تام and فعل ناقص before covering the types of Kana.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`فعل تام`}</strong><br parentName="p"></br>{`
`}{`Example: كتب الطالبُ الدرس  `}</p>
    <ul>
      <li parentName="ul">{`It has حدث (doer or فاعل) i.e. someone is holding pen and writing`}</li>
      <li parentName="ul">{`It has زمن (time) implying it happened in the past`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`فعل ناقص`}</strong><br parentName="p"></br>{`
`}{`Example: كان الطالبُ مجتهداً`}</p>
    <ul>
      <li parentName="ul">{`No حدث (i.e. no فاعل)`}</li>
      <li parentName="ul">{`It has only زمن`}</li>
    </ul>
    <p>{`Here we ony have اسم كان و خبر كان  `}</p>
    <br />
    <p>{`Similarly كان is also divided into two groups, كان ناقص and كان التامة `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`كان التامة`}</strong>{` having حدث and زمن`}</li>
      <li parentName="ul"><strong parentName="li">{`كان ناقص`}</strong>{` having only زمن`}</li>
    </ul>
    <p>{`Example:  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`وإن كان ذو عسرة`}</strong>{` (And if someone is in hardship)`}<br parentName="li"></br>
        {`This كان has حدث`}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`كان`}</strong>{`
فعل تام
`}<strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`ذو`}</strong>{`
فاعل مرفوع`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`خالدين فيها مادامت السموات والأرض`}</strong>{` `}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`السموات`}</strong>{`
فاعل مرفوع`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`كن فيكون`}</strong>{` (Be So it will happen)  `}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`كن`}</strong>{`
فعل ناقص
`}<strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`يكون`}</strong>{`
فعل تام`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`أصبحنا وأصبح الملك لله`}</strong>{` (We entered the time of morning and the universe is owned by Allah)  `}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`أصبح`}</strong>{`
فعل ناقص`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`أصبحنا`}</strong>{`
فعل تام`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`نا`}</strong>{`
ضمير مبني في محل رفع فاعل  `}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`أمسينا وأمسى الملك لله`}</strong>{` (We entered the time of evening and the universe is owned by Allah)`}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p">{`أمسينا`}</strong>{`
فعل تام`}<br parentName="p"></br>{`
`}<strong parentName="p">{`أمسى`}</strong>{`
فعل ناقص`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`نا`}</strong>{`
ضمير مبني في محل رفع فاعل  `}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`فسبحان الله حين تمسون وحين تصبحون`}</strong>{` (So Glory be to Allah when we enter time of evening and time of morning)   `}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`تمسون`}</strong>{`
فعل تام`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`تصبحون`}</strong>{`
فعل تام`}</p>
        </blockquote>
      </li>
    </ul>
    <h2>{`Extra/Additional ب appearing in Kana and Sister`}</h2>
    <ul>
      <li parentName="ul">{`The extra/Additional ب used in Kana and Sister is called الباء زائدة.`}</li>
      <li parentName="ul">{`It comes in sentences having ليس and is added to khabar of ليس`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`تأتي الباء زائدة مع خبر ليس`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`We use الباء زائدة for توكيد. Example,`}
        <ul parentName="li">
          <li parentName="ul">{`ليس الطالبُ `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`ب`}</strong>{`المجتهدِ`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`المجتهد`}</strong>{`
خبر ليس منصوب وعلامة نصبه الفتحة المقدرة`}<br parentName="p"></br>{`
`}{`أو`}<br parentName="p"></br>{`
`}{`مجرور لفظاً منصوب محلاً  `}</p>
            </blockquote>
          </li>
          <li parentName="ul">{`Quranic Examples:`}
            <ul parentName="li">
              <li parentName="ul">{`أليس الله `}<strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`ب`}</strong>{`كاف عبده`}</li>
              <li parentName="ul">{`لست عليهم `}<strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`ب`}</strong>{`مسيطر`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2>{`Reference(s) used in Kana and Sister`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://tanzil.net/",
          "rel": "nofollow noopener"
        }}>{`tanzil.net`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      